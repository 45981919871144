import { useState } from "react";
import logo from "../assets/img/wooblly-name.svg";
import enIcon from "../assets/img/ic_en_language.svg";
import roIcon from "../assets/img/ic_ro_language.svg";
import arrow from "../assets/img/ic_arrow_down.svg";
import "../styles/navbar.css";
import { useTranslation } from "react-i18next";

const Navbar = ({ homeRef, featuresRef, testimonialsRef, contactRef }) => {
  const { t, i18n } = useTranslation("global");
  const [openMenu, setOpenMenu] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [openDropdown, setOpenDropdown] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    setOpenDropdown(false);
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
      setOpenMenu(false);
    }
  };

  return (
    <nav className="nav flip animation-duration-1000">
      <img src={logo} alt="Logo Wooblly" />
      <div className="nav-right">
        <div className={`menu ${openMenu ? "flex" : "display-menu"}`}>
          <a className="menu-item" onClick={() => scrollToSection(homeRef)}>
            {t("navbar_home")}
          </a>
          <a className="menu-item" onClick={() => scrollToSection(featuresRef)}>
            {t("navbar_features")}
          </a>
          <a
            className="menu-item"
            onClick={() => scrollToSection(testimonialsRef)}
          >
            {t("navbar_testimonials")}
          </a>
          <a className="menu-item" onClick={() => scrollToSection(contactRef)}>
            {t("navbar_contact")}
          </a>
          <div className="menu-item language-selector">
            <button className="dropdown-toggle" onClick={toggleDropdown}>
              <img
                src={language === "en" ? enIcon : roIcon}
                alt="Language"
                className="flag-icon"
              />
              <img src={arrow} alt="arrow" className="arrow-icon" />
            </button>
            {openDropdown && (
              <div className="dropdown-menu">
                <button
                  className="dropdown-item"
                  onClick={() => changeLanguage("ro")}
                >
                  <img src={roIcon} alt="România" className="flag-icon" />
                  {t("navbar_language_ro")}
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => changeLanguage("en")}
                >
                  <img src={enIcon} alt="English" className="flag-icon" />
                  {t("navbar_language_en")}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="burger-menu anim-fade-in" onClick={toggleMenu}>
          <span />
          <span />
          <span />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
