import React, { useState, useEffect } from 'react';

const Counter = ({ endValue }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTime;
    const duration = 3000;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;

      const progress = timestamp - startTime;
      const percentage = Math.min(progress / duration, 1);

      setCount(Math.floor(percentage * endValue));

      if (percentage < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);

    return () => {
      startTime = null;
    };
  }, [endValue]);

  return  <span className="countdown-number">{count}</span> ;
};

export default Counter;