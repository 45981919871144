import "../styles/feature.css";

const Feature = ({ icon, feature, description }) => {
  return (
    <div className="feature-card">
      <div className="feature-icon">
        <img src={icon} alt="Feature Icon" />
      </div>
      <h3>{feature}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Feature;
