import logo from "../assets/img/wooblly-name.svg";
import facebook from "../assets/img/facebook.svg";
import instagram from "../assets/img/instagram.svg";
import tiktok from "../assets/img/tiktok.svg";
import { useTranslation } from "react-i18next";
import hypercode from "../assets/img/hypercode-logo.svg";
import screenshotRo from "../assets/img/footer/ss-footer-ro.svg";
import screenshotEn from "../assets/img/footer/ss-footer-en.svg";
import StoreIcons from "../components/StoreLinks.js";
import "../styles/footer.css";

export const Footer = () => {
  const { t, i18n } = useTranslation("global");

  const screenshot = i18n.language === "ro" ? screenshotRo : screenshotEn;

  return (
    <footer className="footer">
      <div className="bg-footer-container">
        <div className="grid-container">
          <div>
            <img src={logo} alt="Wooblly" className="logo" />
            <div className="contact-section">
              <p>Wooblly App</p>
              <p>Str. Armatei 5 C, Romania</p>
              <p>071234324</p>
              <p>support@wooblly.com</p>
            </div>
          </div>
          <div className="links-section">
            <h1 className="text-3xl font-bold text-white">Quick Links</h1>
            <div className="mt-4">
              <a className="links-redirect" href="/terms-and-conditions">
                {t('terms_of_user')}
              </a>
            </div>
            <div className="mt-4">
              <a className="links-redirect" href="/policy-notes">
                {t('privacy_policy')}
              </a>
            </div>
          </div>
          <div className="app-screans-container">
            <div className="app-screens-section">
              <img
                className="img-guest"
                src={screenshot}
                alt="Wooblly Screens"
              />
            </div>
          </div>
        </div>
        <StoreIcons
          customClassName={
            "h-3rem-custom justify-content-start ml-8 show-section"
          }
        />
        <div className="grid-social-container">
          <a
            href="https://www.facebook.com/share/14ud7PhE31/?mibextid=wwXIfr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="social-icon"
              src={facebook}
              alt="Wooblly Facebook"
            />
          </a>

          <a
            href="https://www.instagram.com/wooblly_app?igsh=cjl0YW92eXI1YnR5"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="social-icon"
              src={instagram}
              alt="Wooblly Instagram"
            />
          </a>

          <a
            href="https://www.tiktok.com/@Wooblly"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="social-icon" src={tiktok} alt="Wooblly TikTok" />
          </a>
        </div>
        <div className="developer-section">
          <p>Copyright @2025</p>
          <div className="developer-item">
            <p>{t('footer_owned_by')}</p>
            <p>ADM SOFT DEVELOPMENT</p>
          </div>
          <div className="developer-item">
            <p>{t('footer_developed_by')}</p>
            <a href="https://hypercode.ro/" target="_blank" rel="noopener noreferrer">
              <img src={hypercode} alt="Hypercode Logo" className="hypercode-logo" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
