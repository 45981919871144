import StoreIcons from "../components/StoreLinks";
import icon from "../assets/img/wooblly-icon.svg";
import screenshotRo from "../assets/img/about/ss-about-ro.svg";
import screenshotEn from "../assets/img/about/ss-about-en.svg";
import "../styles/about.css";
import { useTranslation } from "react-i18next";
import "../i18n";

const About = () => {
  const { t, i18n } = useTranslation("global");

  const screenshot = i18n.language === "ro" ? screenshotRo : screenshotEn;

  return (
    <div className="bg-waves-container">
      <div className="about-main-container">
        <div className="block w-12 details-section">
          <div>
            <img src={icon} alt={t('about_title')} className="h-10rem about-logo" />
          </div>
          <p className="text-area">
            {t('about_description_1')}
          </p>
          <p className="text-area">
            {t('about_description_2')}
          </p>
          <p className="text-area">
            {t('about_description_3')}
          </p>
          <StoreIcons customClassName={"h-3rem-custom flex justify-content-start mt-6 mb-4"} />
        </div>
        <div>
          <img src={screenshot} alt={t('about_app_screenshot_alt')} className="image-display" />
        </div>
      </div>
    </div>
  );
};

export default About;
