import "../styles/newsletter.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/img/wooblly.svg";
import email from "../assets/img/email.svg";
import user from "../assets/img/user.svg";
import subscribeIcon from "../assets/img/subscribe-icon.svg";
import { InputField } from "../components/InputField";

const Newsletter = () => {
  const baseUrl = process.env.REACT_APP_WOOBLLY_BASE_URL;
  const { t } = useTranslation("global");

  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [resetFields, setResetFields] = useState(false);

  const subscribeToNewsletter = (e) => {
    e.preventDefault();
    if (!fullName || !validateEmail(emailAddress) || !agreeToTerms) {
      alert(t("newsletter_validation_text"));
      return;
    }

    const requestBody = {
      name: fullName,
      email: emailAddress,
    };
    subscribe(requestBody);
  };

  const subscribe = async (requestBody) => {
    const apiUrl = `${baseUrl}/api/v1/users/subscribe-to-newsletter`;
    await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    alert("Success!");
    setResetFields(true);
  };

  useEffect(() => {
    if (resetFields) {
      setFullName("");
      setEmailAddress("");
      setAgreeToTerms(false);
      setResetFields(false);
    }
  }, [resetFields]);

  const handleEmailChange = (value) => {
    setEmailAddress(value);
  };

  const handleNameChange = (value) => {
    setFullName(value);
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="flex align-items-center justify-content-center newsletter-main-container">
      <div>
        <img
          src={logo}
          className="newsletter-logo mr-8 float-bob-y"
          alt="Wooblly"
        />
      </div>
      <div className="form-container">
        <h1 className="form-title">{t("newsletter_title")}</h1>
        <p className="form-info-text">{t("newsletter_info_text")}</p>
        <form onSubmit={subscribeToNewsletter}>
          <div className="my-2">
            <InputField
              icon={user}
              placeholder={t("newsletter_full_name_placeholder")}
              value={fullName}
              onInputChange={handleNameChange}
            />
          </div>
          <div className="my-2">
            <InputField
              icon={email}
              placeholder={t("newsletter_email_placeholder")}
              value={emailAddress}
              onInputChange={handleEmailChange}
            />
          </div>
          <div className="checkbox-container flex align-items-center mt-3">
            <input
              className="check-box"
              type="checkbox"
              id="agree"
              name="agreeToTerms"
              checked={agreeToTerms}
              onChange={(e) => setAgreeToTerms(e.target.checked)}
            />
            <label htmlFor="agree" className="agree-label">
              {t("newsletter_agreement_text")}{" "}
              <a href="/policy-notes">{t("privacy_policy")}</a>{" "}
              {t("newsletter_and")}{" "}
              <a href="/terms-and-conditions">{t("terms_of_user")}</a>
            </label>
          </div>
          <button type="submit" className="subscribe-btn my-4">
            <img src={subscribeIcon} alt="Subscribe Wooblly" className="mr-2" />
            <span className="ml-2">{"Subscribe now"}</span>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
