import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import iconImage from "../assets/img/wooblly-logo.svg";
import { Password } from "primereact/password";
import "../App.css";
import "../styles/input.css";
import "../i18n";
import SmileyFace from "../assets/img/smiley-face.svg";
import AngryFace from "../assets/img/angry-face.svg";
import UpsetFace from "../assets/img/upset-face.svg";
import HappyFace from "../assets/img/happy-face.svg";
import CheckCircle from "../assets/img/check-circle.svg";

const SetPasswordPage = () => {
  const { t } = useTranslation("global");
  const baseUrl = process.env.REACT_APP_WOOBLLY_BASE_URL;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    number: false,
    capitalLetter: false,
    smallLetter: false,
  });

  const [isErrorMessageDisplayed, setErrorMessageDisplay] = useState({
    notMatch: false,
    notRequirements: false,
  });
  const [isPasswordSuccessfullyUpdated, setPasswordSuccessfullyUpdated] =
    useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessageDisplay((prevState) => ({
        ...prevState,
        notMatch: true,
      }));
      return;
    }

    if (numberOfCheckedRequirements < 3) {
      setErrorMessageDisplay((prevState) => ({
        ...prevState,
        notRequirements: true,
      }));
      return;
    }

    var token = getQueryParam("confirmation-token");

    if (token) {
      const requestBody = {
        password: password,
        confirmationToken: token,
      };
      resetPassword(requestBody);
    }
  };

  const resetPassword = async (requestBody) => {
    try {
      const apiUrl = `${baseUrl}/api/v1/authentication/reset-password`;
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        alert(errorData.message || t("something_went_wrong"));
        return;
      }

      setPasswordSuccessfullyUpdated(true);
      alert(t("password_reset_successful"));
    } catch (error) {
      alert(t("something_went_wrong"));
    }
  };

  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const passwordRequirementsList = [
    {
      label: `8 ${t("characters")}`,
      key: "length",
    },
    {
      label: `1 ${t("capital_letter")}`,
      key: "capitalLetter",
    },
    {
      label: `1 ${t("small_letter")}`,
      key: "smallLetter",
    },
    {
      label: `1 ${t("number")}`,
      key: "number",
    },
  ];

  const numberOfCheckedRequirements = useMemo(() => {
    const requirements = Object.values(passwordRequirements);
    return requirements.filter(Boolean).length;
  }, [passwordRequirements]);

  const passwordStrenghtIcon = useMemo(() => {
    if (numberOfCheckedRequirements === 4) {
      return HappyFace;
    }
    if (numberOfCheckedRequirements >= 3) {
      return UpsetFace;
    }
    if (numberOfCheckedRequirements >= 1) {
      return AngryFace;
    }

    return SmileyFace;
  }, [numberOfCheckedRequirements]);

  const updatePasswordRequirement = useCallback((key, value) => {
    setPasswordRequirements((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const checkPasswordLength = useCallback(
    (password) => {
      updatePasswordRequirement("length", password.length >= 8);
    },
    [updatePasswordRequirement]
  );

  const checkPasswordHasNumber = useCallback(
    (password) => {
      updatePasswordRequirement("number", /\d/.test(password));
    },
    [updatePasswordRequirement]
  );

  const checkPasswordHasCapitalLetter = useCallback(
    (password) => {
      updatePasswordRequirement("capitalLetter", /[A-Z]/.test(password));
    },
    [updatePasswordRequirement]
  );

  const checkPasswordHasSmallLetter = useCallback(
    (password) => {
      updatePasswordRequirement("smallLetter", /[a-z]/.test(password));
    },
    [updatePasswordRequirement]
  );

  const handleCheckPasswordStrenght = useCallback(() => {
    checkPasswordLength(password);
    checkPasswordHasNumber(password);
    checkPasswordHasCapitalLetter(password);
    checkPasswordHasSmallLetter(password);
  }, [
    checkPasswordLength,
    checkPasswordHasNumber,
    checkPasswordHasCapitalLetter,
    checkPasswordHasSmallLetter,
    password,
  ]);

  const handleChangePassword = useCallback((e) => {
    setErrorMessageDisplay({
      notMatch: false,
      notRequirements: false,
    });
    setPassword(e.target.value);
  }, []);

  const handleChangeConfirmPassword = useCallback((e) => {
    setErrorMessageDisplay({
      notMatch: false,
      notRequirements: false,
    });
    setConfirmPassword(e.target.value);
  }, []);

  useEffect(() => {
    handleCheckPasswordStrenght();
  }, [handleCheckPasswordStrenght]);

  return (
    <div className="password-reset-container">
      <img src={iconImage} alt="Woobbly Logo" className="pwd-logo-img"/>
      {isPasswordSuccessfullyUpdated ? (
        <div className="c-password-successfully-updated grid justify-content-center">
          <h1 className="pwd-page-title">{t("passward_successfully_updated")}</h1>
          <p className="pwd-page-text">{t("passward_successfully_updated_details")}</p>
          <img
            src={CheckCircle}
            alt="Check Icon"
            style={{ width: "81px", height: "81px" }}
          />
        </div>
      ) : (
        <>
          <h1 className="pwd-page-title">{t("set_new_password")}</h1>
          <p className="pwd-page-text">{t("reset_passoword")}</p>
          <form onSubmit={handleSubmit} className="c-reset-password-form mt-4">
            <div className="grid m-0 mb-4">
              <div className="col-12 flex justify-content-between align-items-center p-0 mb-2">
                <label htmlFor="password">{t("password")}</label>
                <img
                  src={passwordStrenghtIcon}
                  alt="Lock Icon"
                  style={{ width: "30px", height: "30px" }}
                />
              </div>
              <div className="col-12 relative flex p-0">
                <i className="pi pi-lock lock-icon" />
                <Password
                  id="password"
                  onChange={handleChangePassword}
                  className="c-password-input"
                  placeholder={t("password_placeholder")}
                  toggleMask
                  feedback={false}
                />
              </div>
              {isErrorMessageDisplayed.notRequirements && (
                <p className="danger mb-0 mt-2">{t("set_stronger_password")}</p>
              )}
            </div>
            <div className="input-group">
              <div className="col-12 flex justify-content-between p-0 mb-2">
                <label htmlFor="confirm-password">
                  {t("confirm_password")}
                </label>
              </div>
              <div className="col-12 relative flex p-0">
                <i className="pi pi-lock lock-icon" />
                <Password
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={handleChangeConfirmPassword}
                  className="c-password-input"
                  placeholder={t("confirm_password_placeholder")}
                  toggleMask
                  feedback={false}
                />
              </div>
              {isErrorMessageDisplayed.notMatch && (
                <p className="danger mb-0 mt-2">
                  {t("passwords_do_not_match")}
                </p>
              )}
            </div>

            <div className="password-requirements my-4">
              {t("password_constraints_info")}:
              <ul>
                {passwordRequirementsList.map((requirement, index) => {
                  return (
                    <li key={index}>
                      {!!passwordRequirements[requirement.key] && (
                        <i
                          className="pi pi-check mr-1"
                          style={{
                            color: "green",
                            fontSize: "0.8rem",
                            marginLeft: "-20px",
                          }}
                        />
                      )}

                      {requirement.label}
                    </li>
                  );
                })}
              </ul>
            </div>
            <button type="submit" className="set-password-btn">
              <span>{t("set_password")}</span>
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default SetPasswordPage;
