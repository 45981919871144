import "../styles/inputField.css";

export const InputField = ({ icon, placeholder, onInputChange }) => {
  return (
    <div className="wrapper">
      <img src={icon} className="icon" alt="Icon"/>
      <input
        className="input"
        placeholder={placeholder}
        maxLength={50}
        onChange={(e) => onInputChange(e.target.value)}
      />
    </div>
  );
};
