import Feature from "../components/Feature";
import mic from "../assets/img/mic-icon.svg";
import book from "../assets/img/book-icon.svg";
import smile from "../assets/img/ic_happy_face.svg";
import ai from "../assets/img/ai-icon.svg";
import "../styles/features.css";
import { useTranslation } from "react-i18next";

const Features = () => {
  const { t } = useTranslation("global");

  return (
      <div className="features-container">
        <h1 className="text-center mb-6">{t('features_title')}</h1>
        <div className="grid flex justify-content-center align-items-center gap-8">
          <div className="align-items-center justify-content-center">
            <Feature
              icon={mic}
              feature={t('features_voice_cloning')}
              description={t('features_voice_cloning_description')}
            />
          </div>
          <div className="align-items-center justify-content-center">
            <Feature
              icon={book}
              feature={t('features_storytelling')}
              description={t('features_storytelling_description')}
            />
          </div>
          <div className="align-items-center justify-content-center">
            <Feature
              icon={smile}
              feature={t('features_happy_kids')}
              description={t('features_happy_kids_description')}
            />
          </div>
        </div>
        <div className="flex align-items-center justify-content-end">
          <img src={ai} alt={t('features_ai_icon_alt')} className="ai-icon" />
        </div>
      </div>
  );
};

export default Features;
