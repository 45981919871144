import "../styles/testimonial.css";

const Testimonial = ({ text, avatar, fullName }) => {
  return (
    <div className="testimonial-card col-2">
      <div className="flex">
        <img src={avatar} alt={fullName} />
        <p className="name">{fullName}</p>
      </div>
      <div className="testimonial-content">
        <p className="text">
          {text}
        </p>
      </div>
    </div>
  );
};

export default Testimonial;
