import Testimonial from "../components/Tesimonial";
import { useTranslation } from "react-i18next";
import "../styles/testimonials.css";
import avatar1 from "../assets/img/ic_avatar_1.svg";
import avatar2 from "../assets/img/ic_avatar_2.svg";
import avatar3 from "../assets/img/ic_avatar_3.svg";

const Testimonials = () => {
  const { t } = useTranslation("global");

  return (
    <div className="bg-testimonials">
      <div className="testimonials-bg-container">
        <div className="testimonials-container">
          <h1 className="text-center">{t("testimonials_title")}</h1>
          <div className="grid flex justify-content-center gap-4 flex-wrap mt-6">
            <Testimonial
              fullName={t("testimonials_first_person_name")}
              avatar={avatar1}
              text={t("testimonials_first_person_text")}
            />
            <Testimonial
              fullName={t("testimonials_second_person_name")}
              avatar={avatar2}
              text={t("testimonials_second_person_text")}
            />
            <Testimonial
              fullName={t("testimonials_third_person_name")}
              avatar={avatar3}
              text={t("testimonials_third_person_text")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
