import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../styles/comingSoon.css";
import Counter from "./NumberCounter";

const ComingSoonTimer = () => {
  const { t } = useTranslation("global");

  const calculateTimeLeft = () => {
    const launchDate = new Date("2025-03-21T00:00:00");
    const now = new Date();
    const difference = launchDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="countdown-container">
      <h1 className="title">{t('coming_soon_title')}</h1>
      <div className="countdown">
        <div className="countdown-item">
          <Counter endValue={timeLeft.days} />
          <hr className="separator" />
          <span className="countdown-label">{t('countdown_days')}</span>
        </div>
        <div className="countdown-item">
          <Counter endValue={timeLeft.hours} />
          <hr className="separator" />
          <span className="countdown-label">{t('countdown_hours')}</span>
        </div>
        <div className="countdown-item">
          <Counter endValue={timeLeft.minutes} />
          <hr className="separator" />
          <span className="countdown-label">{t('countdown_minutes')}</span>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonTimer;
