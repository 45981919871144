import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Navbar from "../components/Navbar";
import "../styles/animate.css";
import "../styles/welcome.css";
import "../i18n";
import homeScreenshotEn from "../assets/img/welcome/ss-welcome-en.svg";
import homeScreenshotRo from "../assets/img/welcome/ss-welcome-ro.svg";
import bubble from "../assets/img/bubble.svg";
import bubbleSmile from "../assets/img/bubble-smile.svg";
import kite from "../assets/img/kite.svg";
import About from "./about";
import Features from "./features";
import Testimonials from "./testimonials";
import Newsletter from "./newsletter";
import { Footer } from "../components/Footer";
import ComingSoonTimer from "../components/ComingSoonTimer";

const WelcomePage = () => {
  const homeRef = useRef(null);
  const featuresRef = useRef(null);
  const testimonialsRef = useRef(null);
  const contactRef = useRef(null);
  const { t, i18n } = useTranslation("global");

  const homeScreenshotRes = i18n.language === "ro" ? homeScreenshotRo : homeScreenshotEn;

  return (
    <div className="main-div">
      <div className="main-body">
        <div className="menu-container">
          <Navbar
            homeRef={homeRef}
            featuresRef={featuresRef}
            testimonialsRef={testimonialsRef}
            contactRef={contactRef}
          />
        </div>
        <div ref={homeRef}>
          <div className="welcome-section">
            <h1 className="text-center">
              {t('welcome_title_part1')} <span>{t('welcome_title_span')}</span> {t('welcome_title_part2')}
            </h1>
            <p className="text-center	">
              {t('welcome_description')}
            </p>
            <ComingSoonTimer customClassName={"h-3rem-custom flex justify-content-center fadein animation-duration-1000"} />
          </div>
          <div className="welcome-section-container">
            <img className="kite float-bob-x" src={kite} alt="Wooblly Fun" />
            <img className="bubble fadein animation-duration-1000" src={bubble} alt="Wooblly Stories" />
            <img
              className="screenshot slide"
              src={homeScreenshotRes}
              alt="Wooblly Application Home Screen"
              loading="eager"
            />
            <img
              className="smile-bubble float-bob-y"
              src={bubbleSmile}
              alt="Wooblly Voices"
            />
          </div>
        </div>
      </div>
      <About />
      <div id="id-features" ref={featuresRef}>
        <Features />
      </div>
      <div id="id-testimonials" ref={testimonialsRef}>
        <Testimonials />
      </div>
      <Newsletter />
      <div id="id-contact" ref={contactRef}>
        <Footer />
      </div>
    </div>
  );
};

export default WelcomePage;
