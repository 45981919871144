import { useTranslation } from "react-i18next";
import "../App.css";
import "../i18n";

const PolicyNotesPage = () => {
  const { t } = useTranslation("global");

  return (
    <div className="legal-notes-container main-div pl-6 pr-6">
      <h1>{t("policy_notes_title")}</h1>
      <p>{t("policy_notes_revision_text")}</p>

      <section>
        <h3>{t("policy_notes_general_title")}</h3>
        <p>{t("policy_notes_general_text")}</p>
      </section>

      <section>
        <h3>{t("policy_notes_collect_info_title")}</h3>
        <p/>
        <h4>1. {t("policy_notes_collect_info_personal_data_title")}</h4>
        <p>{t("policy_notes_collect_info_personal_data_text_1")}</p>
        <p>{t("policy_notes_collect_info_personal_data_text_2")}</p>
      </section>

      <section>
        <h4>2. {t("policy_notes_collect_info_data_title")}</h4>
        <p>{t("policy_notes_collect_info_data_text")}</p>
      </section>

      <section>
        <h4>3. {t("policy_notes_collect_info_location_title")}</h4>
        <p>{t("policy_notes_collect_info_location_text")}</p>
      </section>

      <section>
        <h4>4. {t("policy_notes_collect_info_data_usage_title")}</h4>
        <p>{t("policy_notes_collect_info_data_usage_text")}</p>
        <ul>
          <li>{t("policy_notes_collect_info_data_usage_ul_1")}</li>
          <li>{t("policy_notes_collect_info_data_usage_ul_2")}</li>
          <li>{t("policy_notes_collect_info_data_usage_ul_3")}</li>
          <li>{t("policy_notes_collect_info_data_usage_ul_4")}</li>
          <li>{t("policy_notes_collect_info_data_usage_ul_5")}</li>
          <li>{t("policy_notes_collect_info_data_usage_ul_6")}</li>
          <li>{t("policy_notes_collect_info_data_usage_ul_7")}</li>
        </ul>
        <p />
      </section>
      <section>
        <h4>5. {t("policy_notes_data_storage_title")}</h4>
        <p>{t("policy_notes_data_storage_text_1")}</p>
        <p>{t("policy_notes_data_storage_text_2")}</p>
      </section>

      <section>
        <h3>{t("policy_notes_delete_account_title")}</h3>
        <p>
          {t("policy_notes_delete_account_text")}
          <a href="mailto:help@wooblly.com"> help@wooblly.com</a>.
        </p>
      </section>

      <section>
        <h3>{t("policy_notes_third_parties_title")}</h3>
        <p>{t("policy_notes_third_parties_text")}</p>
      </section>

      <section>
        <h3>{t("policy_notes_data_protection_title")}</h3>
        <p>{t("policy_notes_data_protection_text")}</p>
      </section>

      <section>
        <h3>{t("policy_notes_comertial_transactions_title")}</h3>
        <p>{t("policy_notes_comertial_transactions_text")}</p>
      </section>

      <section>
        <h3>{t("policy_notes_service_supliers_title")}</h3>
        <p>{t("policy_notes_service_supliers_text")}</p>
      </section>

      <section>
        <h3>{t("policy_notes_payments_title")}</h3>
        <p>{t("policy_notes_payments_text")}</p>
      </section>
      <section>
        <h3>{t("policy_notes_policies_changes_title")}</h3>
        <p>{t("policy_notes_policies_changes_text_1")}</p>
        <p>{t("policy_notes_policies_changes_text_2")}</p>
      </section>
      <section>
        <h3>{t("policy_notes_extra_information_title")}</h3>
        <p>{t("policy_notes_extra_information_text_1")}</p>
        <p>{t("policy_notes_extra_information_text_2")}</p>
      </section>
    </div>
  );
};

export default PolicyNotesPage;
