import google from "../assets/img/ic_google_play.svg";
import apple from "../assets/img/ic_apple_play.svg";
import "../styles/storeIcons.css";

const StoreIcons = ({ customClassName }) => {
  const appleUrl = process.env.REACT_APP_WOOBLLY_APPLE_STORE_URL;
  const googleUrl = process.env.REACT_APP_WOOBLLY_GOOGLE_STORE_URL;

  const navigate = (url) => {
    window.location.href = url;
  };

  return (
    <div className={`store-icons ${customClassName}`}>
      <button className="store-btn" onClick={() => navigate(googleUrl)}>
        <img className="store-icon" src={google} alt="Google Play Store" />
        <span style={{ color: "#333333" }}>Play Store</span>
      </button>
      <button className="store-btn" onClick={() => navigate(appleUrl)}>
        <img className="store-icon" src={apple} alt="Apple App Store" />
        <span style={{ color: "#333333" }}>App Store</span>
      </button>
    </div>
  );
};
export default StoreIcons;
